import React, { useMemo, useState, useEffect } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import Home from "../header/index";
import { toast } from "react-toastify";

const Orders = () => {
  const serverUrl = process.env.REACT_APP_SHOPIFY_URL;
  const [data, setData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);

  const fetchData = () => {
    const myHeaders = new Headers();
    myHeaders.append("X-Shopify-Access-Token", "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");
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    fetch(`${serverUrl}/orders.json`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data.orders);
        toast.success("Orders fetched successfully");
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Order ID",
        Cell: ({ cell }) => {
          const id = cell.row.original.id;
          return (
            <a
              href={`https://www.bti-usa.com/public/item/${id}?track=true`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {id}
            </a>
          );
        },
      },
      {
        accessorKey: "current_price",
        header: "Subtotal Price",
        Cell: ({ cell }) => <div className="h6">{cell.row.original.current_subtotal_price}</div>,
      },
      {
        accessorKey: "your_price",
        header: "Amount",
        Cell: ({ cell }) => (
          <div className="h6">{cell.row.original.current_subtotal_price_set.shop_money.amount}</div>
        ),
      },
      {
        accessorKey: "first_name",
        header: "First Name",
        Cell: ({ cell }) => <div className="h6">{cell.row.original.customer.first_name}</div>,
      },
      {
        accessorKey: "last_name",
        header: "Last Name",
        Cell: ({ cell }) => <div className="h6">{cell.row.original.customer.last_name}</div>,
      },
      {
        accessorKey: "customer_address",
        header: "Customer Address",
        Cell: ({ cell }) => (
          <div className="h6">{cell.row.original.customer.default_address.address1}</div>
        ),
      },
      {
        accessorKey: "customer_city",
        header: "Customer City",
        Cell: ({ cell }) => (
          <div className="h6">{cell.row.original.customer.default_address.city}</div>
        ),
      },
      {
        accessorKey: "customer_state",
        header: "Customer State",
        Cell: ({ cell }) => (
          <div className="h6">{cell.row.original.customer.default_address.province}</div>
        ),
      },
      {
        accessorKey: "customer_zip",
        header: "Customer Zip Code",
        Cell: ({ cell }) => (
          <div className="h6">{cell.row.original.customer.default_address.zip}</div>
        ),
      },

      {
        accessorKey: "shipping_name",
        header: "Shipping name",
        Cell: ({ cell }) => <div className="h6">{cell.row.original?.shipping_address?.name}</div>,
      },
      {
        accessorKey: "shipping_address",
        header: "Shipping Address",
        Cell: ({ cell }) => (
          <div className="h6">{cell.row.original?.shipping_address?.address1}</div>
        ),
      },

      {
        accessorKey: "shipping_city",
        header: "Shipping City",
        Cell: ({ cell }) => <div className="h6">{cell.row.original?.shipping_address?.city}</div>,
      },
      {
        accessorKey: "shipping_State",
        header: "Shipping State",
        Cell: ({ cell }) => (
          <div className="h6">{cell.row.original?.shipping_address?.province}</div>
        ),
      },
      {
        accessorKey: "shipping_number",
        header: "Shipping phone number",
        Cell: ({ cell }) => <div className="h6">{cell.row.original?.shipping_address?.phone}</div>,
        size: "250",
      },
      {
        accessorKey: "shipping_zip",
        header: "Shipping Zip no",
        Cell: ({ cell }) => <div className="h6">{cell.row.original?.shipping_address?.zip}</div>,
      },
      {
        accessorKey: "shipping_line",
        header: "Shipping line",
        Cell: ({ cell }) => <div className="h6">{cell.row.original?.shipping_lines[0].code}</div>,
      },
      {
        accessorKey: "shipping_line discounted price",
        header: "Shipping line discounted price",
        Cell: ({ cell }) => (
          <div className="h6">{cell.row.original?.shipping_lines[0].discounted_price}</div>
        ),
        size: 300,
      },
      {
        accessorKey: "shipping_line price",
        header: "Shipping line price",
        Cell: ({ cell }) => <div className="h6">{cell.row.original?.shipping_lines[0].price}</div>,
      },
      {
        accessorKey: "shipping_line source",
        header: "Shipping line source",
        Cell: ({ cell }) => <div className="h6">{cell.row.original?.shipping_lines[0].source}</div>,
      },
      {
        accessorKey: "subtotal_price",
        header: "Subtotal Price",
        Cell: ({ cell }) => <div className="h6">{cell.row.original?.subtotal_price}</div>,
      },
      {
        accessorKey: "total_line_items_price_set",
        header: "Total line items Price",
        Cell: ({ cell }) => (
          <div className="h6">
            {cell.row.original?.total_line_items_price_set.presentment_money.amount}
          </div>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enablePagination: true,
    enableRowSelection: true,
    enableStickyHeader: true,
    getRowId: (row) => row.id,
    initialState: {
      rowPinning: {
        top: data.length > 0 ? [data[0].id] : [],
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: "500px",
      },
    },
  });

  useEffect(() => {
    const selectedRowIds = table.getState().rowSelection;
    const selectedIdsArray = Object.keys(selectedRowIds);

    const selectedRowsData = data.filter((row) => selectedIdsArray.includes(row.id.toString()));
    setSelectedRowData(selectedRowsData);
  }, [table, table.getState().rowSelection, data]);

  return (
    <>
      <Home />
      <div className="d-flex">
        <div className="container w-75">
          <h1 className="text-center m-5">Orders</h1>
          <MaterialReactTable table={table} />
          {selectedRowData.length > 0 && (
            <div>
              <div className="row">
                <h1 className="text-center m-5">Selected Products</h1>
              </div>
              {selectedRowData.map((row) => {
                <div> {table}</div>;
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Orders;
