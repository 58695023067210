import "./App.css";
import { Route, Routes } from "react-router-dom";
import BtiProducts from "./components/btiProducts";
import CategoryProducts from "./components/categoryProducts";
import ExistingProducts from "./components/existing-products";
import RemovedProducts from "./components/removed-products";
import SaleProducts from "./components/SaleProducts";
import Dashboard from "./components/dashboard";
import LoginPage from "./components/Login";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./ProtectedRoute";
import ChangePassword from "./components/changePassword";
import ForgotPasswordPage from "./components/forgot-password";
import ResetPasswordPage from "./components/reset-password";
import Orders from "./components/orders";
import MerchantProducts from "./components/merchantProducts";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/bti-products"
          element={
            <ProtectedRoute>
              <BtiProducts />
            </ProtectedRoute>
          }
        />
        <Route
          path="/existing-products"
          element={
            <ProtectedRoute>
              <ExistingProducts />
            </ProtectedRoute>
          }
        />
        <Route
          path="/removed-products"
          element={
            <ProtectedRoute>
              <RemovedProducts />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sale-products"
          element={
            <ProtectedRoute>
              <SaleProducts />
            </ProtectedRoute>
          }
        />
        <Route
          path="/products/:category_id/:subcategory_id/:manufacturer_id"
          element={
            <ProtectedRoute>
              <CategoryProducts />
            </ProtectedRoute>
          }
        />
        <Route
          path="/orders"
          element={
            <ProtectedRoute>
              <Orders />
            </ProtectedRoute>
          }
        />
        <Route
          path="/merchant-products"
          element={
            <ProtectedRoute>
              <MerchantProducts />
            </ProtectedRoute>
          }
        />
        <Route
          path="/change-password"
          element={
            <ProtectedRoute>
              <ChangePassword />
            </ProtectedRoute>
          }
        />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
