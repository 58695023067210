import React, { useEffect, useState } from "react";
import DoughnutChart from "./doughnutChart";
import BarChart from "./barChart";
import Header from "../header";
import "./index.css";

const Dashboard = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [totalProducts, setTotalProducts] = useState("");
  const [deletedVariants, setDeletedVariants] = useState("");
  const [notUploaded, setNotUploaded] = useState("");
  const [uploadedProducts, setUploadedProducts] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [categoryProductData, setCategoryProductData] = useState([]);
  const [products, setProducts] = useState([]);
  const [countOnSale, setCountOnSale] = useState("");
  const [countCloseOut, setCountCloseOut] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch(`${serverUrl}/get_stats.php`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setTotalProducts(data.data.count_total);
        setDeletedVariants(data.data.count_deleted_variants);
        setNotUploaded(data.data.count_not_uploaded);
        setUploadedProducts(data.data.count_uploaded_variants);
        setProducts(data.data.products);
        setCountOnSale(data.data.count_on_sale);
        setCountCloseOut(data.data.count_close_out);
        const categories = data.data.categories.map((category) => ({
          name: category.category_name,
          percentageMargin: parseFloat(category.percentage_margin),
        }));
        setCategoryData(categories);

        const productCounts = data.data.categories.map((category) => ({
          name: category.category_name,
          productCount: parseInt(category.total_products, 10),
        }));
        setCategoryProductData(productCounts);
      })
      .catch((error) => {
        console.error("Departments Error:", error);
      });
  };

  return (
    <>
      <Header />
      <div className="container text-center">
        <div className="row g-4 mt-4">
          <div className="col-md-7 mt-4 h-100">
            <div className="card column">
              <div className="card-body">
                <p>Top 10 products with highest margin</p>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Item ID</th>
                      <th>Item Description</th>
                      <th>MSRP</th>
                      <th>Your Price</th>
                      <th>Margin</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((product) => (
                      <tr key={product.id}>
                        <td>{product.id}</td>
                        <td>{product.item_description}</td>
                        <td>${product.msrp}</td>
                        <td>${product.your_price}</td>
                        <td>${Number(product.msrp - product.your_price).toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-md-5 mt-4 h-100">
            <div className="row">
              <div className="col-md-12">
                <div className="card column">
                  <div className="card-body">
                    <DoughnutChart categoryProductData={categoryProductData} />
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <div className="card column">
                  <div className="card-body">
                    <h5 className="card-title main-heading">On Sale Products</h5>
                    <p className="card-text content">{countOnSale}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <div className="card column">
                  <div className="card-body">
                    <h5 className="card-title main-heading">Close out Products</h5>
                    <p className="card-text content">{countCloseOut}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-4 mt-4">
          <div className="col-md-6 mt-4">
            <div className="card column">
              <div className="card-body">
                <h5 className="card-title main-heading">Total Products</h5>
                <p className="card-text content">{totalProducts}</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-4">
            <div className="card column">
              <div className="card-body">
                <h5 className="card-title main-heading">Deleted Variants</h5>
                <p className="card-text content">{deletedVariants}</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-4">
            <div className="card column">
              <div className="card-body">
                <h5 className="card-title main-heading">Uploaded Products</h5>
                <p className="card-text content">{uploadedProducts}</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-4">
            <div className="card column">
              <div className="card-body">
                <h5 className="card-title main-heading">Not Uploaded Products</h5>
                <p className="card-text content">{notUploaded}</p>
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <div className="card column">
              <div className="card-body">
                <BarChart categoryData={categoryData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
